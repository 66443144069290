import React, {Component} from 'react'
import './user_edit_popup.scss'
import cur_icon_close_gre from "../../../images/cur_icon_close_gre.png";
import check_icon from '../../../images/login/check_icon.png';
import check_icon_active from '../../../images/login/check_icon_active.png';
import code_icon from '../../../images/login/code_icon.png';
import {Button, Input, message} from 'antd';
import {desired_info_list} from '../Values/user_data'
import {checkPhoneRegistered, getSmsVerification} from '../../../api/token'
import PhoneInput from "react-phone-input-2";

export default class edit_popup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            code_button_disabled: false,//禁止
            code_button_loading: false,//加载
            code_button_text: '获取验证码',//验证码文字
            country_code: '+86', // Default country code
            edit_type: "",
            edit_value: "",
            prev_phone: "",
            edit_display_type: "common",
            desired_info_list: desired_info_list,
            sms_code: "",
            edit_info_obj: {
                first_name: "用户昵称",
                phone: "电话号码",
                department: "部门",
                position: "职位",
                desired_info: "想了解的信息",
                brand: "品牌名称"
            }
        }
    }

    setEditInfo(obj) {
        let {edit_type, edit_value} = obj
        let edit_display_type = "common"
        if (edit_type === "desired_info") {
            edit_display_type = "desired_info"
            let desired_info_list = JSON.parse(JSON.stringify(this.state.desired_info_list))
            for (let i = 0; i < edit_value.length; i++) {
                let index = parseInt(edit_value[i]) - 1
                desired_info_list[index].is_active = true
            }
            this.setState({
                desired_info_list: desired_info_list
            })
        } else if (edit_type === "phone") {
            edit_display_type = "phone"
            this.setState({
                prev_phone: edit_value
            })
        }
        this.setState({
            edit_type: edit_type,
            edit_value: edit_value,
            edit_display_type: edit_display_type,
            sms_code: "",
            code_button_disabled: false,//禁止
            code_button_loading: false,//加载
            code_button_text: '获取验证码',//验证码文字
        })
    }

    tags_close() {
        this.props.handleEditInfo({
            is_confirm: false,
            show_edit_popup: false,
        })
    }

    tags_confirm() {
        let edit_value = this.state.edit_value
        if (this.state.edit_type === "desired_info") {
            if (!this.state.desired_info_list.length) {
                message.warning(`想信息渠道不可为空`, 2, () => {
                })
                return
            }
            edit_value = []
            for (let i = 0; i < this.state.desired_info_list.length; i++) {
                if (this.state.desired_info_list[i].is_active) {
                    edit_value.push(String(i + 1))
                }
            }
        } else if (this.state.edit_type === "phone") {
            if (!this.state.edit_value) {
                message.warning(`手机不可为空`, 2, () => {
                })
                return
            } else if (!this.state.sms_code) {
                message.warning(`验证码不可为空`, 2, () => {
                })
                return
            }
        }
        if (this.state.edit_value === "") {
            message.warning(`${this.state.edit_info_obj[this.state.edit_type]}不可为空`, 2, () => {
            })
            return
        }
        this.props.handleEditInfo({
            is_confirm: true,
            show_edit_popup: false,
            edit_type: this.state.edit_type,
            edit_value: edit_value,
            sms_code: this.state.sms_code
        })
    }

    // 输入输入框
    handleInput(event) {
        this.setState({
            edit_value: event.target.value
        })
    }

    handlePhoneChange = (value, countryData) => {
        this.setState({
            edit_value: `+${value}`,
            country_code: `+${countryData.dialCode}`
        });
    }

    // 修改验证码
    handleSmsInput(event) {
        this.setState({
            sms_code: event.target.value
        })
    }

    // 切换渠道信息checkbox
    handleCheckbox(index) {
        let desired_info_list = JSON.parse(JSON.stringify(this.state.desired_info_list))
        desired_info_list[index].is_active = !desired_info_list[index].is_active
        this.setState({
            desired_info_list: desired_info_list
        })
    }

    // 只能输入数字的字符
    validateNumber(event) {
        event.target.value = event.target.value.replace(/^[^\d]+/g, '')
        // event.target.value = event.target.value.replace(/^(0+)|[^\d]+/g, '')
    }

    //验证手机号
    validatePhone(event) {
        const phoneRegex = /^(\+?[1-9]{1}[0-9]{1,3})?[1]([3-9][0-9]{9})$|^\+?[1-9]{1}[0-9]{1,3}[0-9]{7,15}$/;
        event.target.value = event.target.value.replace(phoneRegex, '')
        // event.target.value = event.target.value.replace(/^(0+)|[^\d]+/g, '')
    }

    // 判断手机号
    isPhone(val) {
        const phoneRegex = /^(\+?[1-9]{1}[0-9]{1,3})?[1]([3-9][0-9]{9})$|^\+?[1-9]{1}[0-9]{1,3}[0-9]{7,15}$/;
        return phoneRegex.test(val)
    }

    // 获取短信验证码
    sendCode(t) {
        if (!this.isPhone(this.state.edit_value)) {
            message.warning('请输入正确的手机号', 2, () => {
            })
            return
        } else if (this.state.prev_phone === this.state.edit_value) {
            message.warning('手机号与之前一致，无需修改', 2, () => {
            })
            return
        }
        // 判断手机号是否已经注册
        this.checkPhoneRegistered()
    }

    // 检测手机号是否已经注册
    async checkPhoneRegistered() {
        try {
            const response = await checkPhoneRegistered({
                phone_number: this.state.edit_value,
            })
            let flag = response.data.flag
            let msg = response.data.message
            if (flag === 0) {
                message.warning(msg, 2, () => {
                })
                return
            }
            this.setState({
                code_button_loading: true,
                code_button_text: "发送中",
            }, () => {
                let expire = 60
                // if (t === "phone_login") {
                //     expire = 60
                // }
                this.getSmsVerification(expire)
            })
            return response;
        } catch (error) {
            this.setState({
                code_button_loading: false,
                code_button_text: "重新获取"
            })
            message.error('请重新获取验证码!')
            throw error;
        }
    }

    // 手机获取验证码（登录）
    async getSmsVerification(expire) {//验证并成功跳转
        try {
            const response = await getSmsVerification({
                country_code: this.state.country_code,
                phone_number: this.state.edit_value,
                expire: expire
                // phone_number: "17717281798",
            })
            this.countDown(expire)
            return null;
        } catch (error) {
            this.setState({
                code_button_loading: false,
                code_button_text: "重新获取"
            })
            message.error('请重新获取验证码!')
            throw error;
        }
    }

    // 倒计时
    countDown = (expire) => {
        // 定时器
        let timer = null
        // 倒计时时间
        let sec = expire
        //修改状态
        this.setState({
            code_button_loading: false,
            code_button_disabled: true,
            code_button_text: `${sec}s`
        })

        timer = setInterval(() => {
            sec--;
            if (sec <= 0) {
                this.setState({
                    code_button_text: `重新获取`,
                    code_button_disabled: false,
                })
                clearInterval(timer);
                return false
            }
            this.setState({
                code_button_text: `${sec}s`
            })
        }, 1000)
    }

    render() {
        const prefix_2 = (
            <img src={code_icon} alt="" className='evaluation-icon-search'/>
        )
        let edit_name = this.state.edit_info_obj[this.state.edit_type]
        return (
            <div className="user-edit-popup-container">
                <div className="user-edit-popup-bg">
                    <div className="user-edit-popup-main">
                        <div className='user-edit-popup-top'>
                            <div className='popup-top-title'>修改{edit_name}</div>
                            <div className='popup-delete-icon'>
                                <img src={cur_icon_close_gre} className='cursor' onClick={this.tags_close.bind(this)}
                                     alt=""/>
                            </div>
                        </div>
                        <div className="user-edit-popup-content">
                            <div className="edit-item">
                                <div className="item-name">
                                    {this.state.edit_info_obj[this.state.edit_type]}
                                </div>
                                {
                                    this.state.edit_display_type === "common" ? <div className="item-input-wrapper">
                                        <Input type="text" className="item-input" value={this.state.edit_value}
                                               placeholder={`请填写您的${edit_name}`}
                                               onChange={this.handleInput.bind(this)}/>
                                    </div> : ""
                                }
                                {
                                    this.state.edit_display_type === "desired_info" ?
                                        <div className="card-check-wrapper">
                                            {
                                                this.state.desired_info_list.map((item, index) => {
                                                    return <div className="card-check" key={index}>
                                                        <div className='checkbox'
                                                             onClick={this.handleCheckbox.bind(this, index)}>
                                                            {
                                                                item.is_active ? <img src={check_icon_active} alt=""/> :
                                                                    <img src={check_icon} alt=""/>
                                                            }
                                                        </div>
                                                        <div className='check-text'>{item.val}</div>
                                                    </div>
                                                })
                                            }
                                        </div> : ""
                                }
                                {
                                    this.state.edit_display_type === "phone" ? <div className="phone-card-wrapper">
                                        <div className="item-input-wrapper">
                                            {/*<Input type="text" className="item-input"
                                                   value={this.state.edit_value}
                                                   placeholder="请输入您的联系方式…"
                                                   onChange={this.handleInput.bind(this)}
                                                   onInput={this.validateNumber.bind(this)}/>*/}
                                            <PhoneInput
                                                className="phone-input item-input"
                                                value={this.state.edit_value}
                                                onChange={this.handlePhoneChange}
                                                onInput={this.validatePhone.bind(this)}
                                                inputClass="phone-input"
                                                placeholder="请输入您的联系方式…"
                                            />
                                        </div>
                                        <div className="card-item sms-card-item">
                                            <div className="item-name">
                                                验证码
                                            </div>
                                            <div className="item-input-wrapper">
                                                <Input.Group compact>
                                                    <Input prefix={prefix_2} className="sms-input"
                                                           placeholder="请输入短信验证码" value={this.state.sms_code}
                                                           onChange={this.handleSmsInput.bind(this)} maxLength={6}
                                                           onInput={this.validateNumber.bind(this)}/>
                                                    <Button className="sms-btn" onClick={this.sendCode.bind(this)}
                                                            disabled={this.state.code_button_disabled}
                                                            loading={this.state.code_button_loading}>{this.state.code_button_text}</Button>
                                                </Input.Group>
                                            </div>
                                        </div>
                                    </div> : ""
                                }
                            </div>
                        </div>
                        <div className='tag-btn'>
                            <div onClick={this.tags_close.bind(this)}>取消</div>
                            <div onClick={this.tags_confirm.bind(this)}>保存</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
